<!-- <cdk-accordion #wrapper>
  <cdk-accordion-item #accordionItem="cdkAccordionItem" class="headings"
    ><div class="toggler" (click)="accordionItem.toggle()">
      <svg-icon key="refs-menu" />
      <span i18n class="toggler__title">Содержание</span>
      <svg-icon key="arrow" [class.arrow-up]="accordionItem.expanded" />
    </div>

    <div
      #headingsContainer
      [style.display]="accordionItem.expanded ? '' : 'none'">
      <ng-container *ngTemplateOutlet="headings"></ng-container></div
  ></cdk-accordion-item>
</cdk-accordion> -->

<div class="ql-container">
  <!-- <div
    class="trailing-headings"
    [class.trailing-headings_active]="!headingsVisible"
    [class.trailing-headings_opened]="trailingHeadingsVisible">
    @if (!trailingHeadingsVisible) {
      <svg-icon key="refs-menu" (click)="toggleTrailingHeadings()" />
    } @else {
      <div class="trailing-headings__header">
        <span i18n class="toggler__title">Содержание</span>
        <svg-icon key="cross" (click)="toggleTrailingHeadings()"></svg-icon>
      </div>
      <div class="trailing-headings__content">
        <ng-container *ngTemplateOutlet="headings"></ng-container>
      </div>
    }
  </div> -->

  <div
    #textEditor
    class="ql-editor"
    [style.marginTop.px]="trailingHeadingsHeight"
    [innerHTML]="pageContent + watermark | trustHtml"></div>
</div>

<ng-template #headings>
  @for (heading of headingsConfig; track $index) {
    <span
      [class]="'t-heading-6' + ' ' + 'article-' + heading.tag"
      [ngClass]="{
        't-semi-bold': heading.tag === 'h1',
        article_active: heading.id === activeTrailingHeadingId,
      }"
      (click)="moveToHeading(heading.id)">
      {{ heading.text }}</span
    >
  }
</ng-template>
