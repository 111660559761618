import { createAction, props } from '@ngrx/store';

import { ViewerTool } from './viewer-state.interface';

export const setViewerNavigationVisibility = createAction(
  '[Viewer] Set Navigation Visibility',
  props<{ navigationVisibility: boolean }>()
);

export const setActiveTool = createAction(
  '[Viewer] Set Active Tool',
  props<{ activeTool: ViewerTool }>()
);

export const setCursorTrailStatus = createAction(
  '[Viewer] Set Cursor Activity Status',
  props<{ cursorTrailActive: boolean }>()
);

export const setPen2dNavHintStatus = createAction(
  '[Viewer] Set Pen 2D Nav Hint Activity Status',
  props<{ pen2dNavHintActive: boolean }>()
);

export const setPen3dNavHintStatus = createAction(
  '[Viewer] Set Pen 3D Nav Hint Activity Status',
  props<{ pen3dNavHintActive: boolean }>()
);

export const setPartArticleOpenedStatus = createAction(
  '[Viewer] Set Part Article Opened Status',
  props<{ partArticleOpened: boolean }>()
);

export const setModelLoadingStatus = createAction(
  '[Viewer] Set Model Loading Status',
  props<{ modelLoaded: boolean }>()
);
