import { createReducer, on } from '@ngrx/store';

import { IViewerState, ViewerTool } from './viewer-state.interface';
import {
  setActiveTool,
  setCursorTrailStatus,
  setModelLoadingStatus,
  setPartArticleOpenedStatus,
  setPen2dNavHintStatus,
  setPen3dNavHintStatus,
  setViewerNavigationVisibility,
} from './viewer.actions';

export const viewerFK = 'viewerFK';

export const initialState: IViewerState = {
  activeTool: ViewerTool.Cursor,
  cursorTrailActive: false,
  modelLoaded: false,
  navigationVisibility: false,
  partArticleOpened: false,
  pen2dNavHintActive: false,
  pen3dNavHintActive: false,
};

export const viewerReducer = createReducer(
  initialState,

  on(
    setViewerNavigationVisibility,
    (state, action): IViewerState => ({
      ...state,
      navigationVisibility: action.navigationVisibility,
    })
  ),

  on(
    setActiveTool,
    (state, action): IViewerState => ({
      ...state,
      activeTool: action.activeTool,
    })
  ),

  on(
    setCursorTrailStatus,
    (state, action): IViewerState => ({
      ...state,
      cursorTrailActive: action.cursorTrailActive,
    })
  ),

  on(
    setPen2dNavHintStatus,
    (state, action): IViewerState => ({
      ...state,
      pen2dNavHintActive: action.pen2dNavHintActive,
    })
  ),

  on(
    setPen3dNavHintStatus,
    (state, action): IViewerState => ({
      ...state,
      pen3dNavHintActive: action.pen3dNavHintActive,
    })
  ),

  on(
    setPartArticleOpenedStatus,
    (state, action): IViewerState => ({
      ...state,
      partArticleOpened: action.partArticleOpened,
    })
  ),
  on(
    setModelLoadingStatus,
    (state, action): IViewerState => ({
      ...state,
      modelLoaded: action.modelLoaded,
    })
  )
);
