export interface IViewerState {
  activeTool: ViewerTool;
  cursorTrailActive: boolean;
  modelLoaded: boolean;
  navigationVisibility: boolean;
  partArticleOpened: boolean;
  pen2dNavHintActive: boolean;
  pen3dNavHintActive: boolean;
}

export enum ViewerTool {
  Cursor = 'cursor',
  Image = 'image',
  Pen2D = 'pen2D',
  Pen3D = 'pen3D',
  Screenshot = 'screenshot',
  Text = 'text',
}
