import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PathologyType } from '@app/shared/models/interfaces/pathology-type';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PathologyTypeApiService {
  constructor(private http: HttpClient) {}

  public getPathologyType$(
    pathologyTypeUuid: string
  ): Observable<PathologyType> {
    return this.http.get<PathologyType>(
      `${environment.apiUrl}/v5/categories/pathologies/types/${pathologyTypeUuid}`
    );
  }
}
