import { CdkAccordionModule } from '@angular/cdk/accordion';
import {
  DOCUMENT,
  isPlatformBrowser,
  NgClass,
  NgTemplateOutlet,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  ARTICLES_LIST_URL_REGEXP,
  PATHOLOGIES_URL_REGEXP,
} from '@app/shared/models/constants/regexp';
import { Theme } from '@app/shared/models/enums/theme.enum';
import { IArticleItem } from '@app/shared/models/interfaces/article/article-item.interface';
import { Sort } from '@app/shared/models/types/sort.type';
import { CustomTranslatePipe } from '@app/shared/pipes/custom-translate/custom-translate.pipe';
import { TrustHtmlPipe } from '@app/shared/pipes/sanitize-html/trust-html.pipe';
import { ThemeService } from '@app/shared/services/theme/theme.service';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Store } from '@ngrx/store';
import { setSortKey } from '@store/sort/sort.actions';
import { filter, take } from 'rxjs';

interface HeadingConfig {
  id: string;
  tag: string;
  text: string;
}

@Component({
  selector: 'app-article-viewer',
  templateUrl: './article-viewer.component.html',
  styleUrls: ['./article-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CdkAccordionModule,
    CustomTranslatePipe,
    NgClass,
    NgTemplateOutlet,
    SvgIconComponent,
    TrustHtmlPipe,
  ],
})
export class ArticleViewerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() public set article(val: IArticleItem) {
    this.setHeadingConfig(val);
  }

  @ViewChild('headingsContainer')
  public headingsContainer: ElementRef<HTMLDivElement>;

  @ViewChild('wrapper')
  public wrapper: ElementRef<HTMLElement>;

  @ViewChild('textEditor')
  public textEditor: ElementRef<HTMLElement>;

  public pageContent: string = null;

  public trailingHeadingsHeight = 0;

  public activeTrailingHeadingId: string = null;

  public headingsVisible = true;

  public trailingHeadingsVisible = false;

  public headingsConfig: HeadingConfig[] = [];

  public watermark =
    '<img class="watermark" src="assets/svg/articles/watermark.svg" />';

  private customTranslate = new CustomTranslatePipe();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object,
    private cdr: ChangeDetectorRef,
    private injector: Injector,
    private renderer: Renderer2,
    private router: Router,
    private store: Store,
    private themeService: ThemeService
  ) {}

  public ngOnInit(): void {
    this.changeWatermarkOnThemeChange();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngAfterViewInit(): void {
    // this.watchHeadingsVisibility();
    // this.watchActiveHeading();
  }

  public ngOnDestroy(): void {
    this.restoreSortState();
  }

  public moveToHeading(id: string): void {
    this.document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }

  public toggleTrailingHeadings(): void {
    this.trailingHeadingsVisible = !this.trailingHeadingsVisible;
    this.trailingHeadingsHeight = this.trailingHeadingsVisible
      ? -50 - this.headingsConfig.length * 50
      : -50;

    this.cdr.markForCheck();
  }

  private restoreSortState(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(event => {
        const e = event as NavigationEnd;
        this.setSortToDefault(e.url);
      });
  }

  private setSortToDefault(url: string): void {
    if (
      !(PATHOLOGIES_URL_REGEXP.test(url) || ARTICLES_LIST_URL_REGEXP.test(url))
    ) {
      this.store.dispatch(setSortKey({ sortKey: Sort.Priority }));
    }
  }

  private setHeadingConfig(article: IArticleItem): void {
    this.pageContent = this.customTranslate.transform(article, 'page');

    if (!this.pageContent) return;

    const headingRegex = /<h[1-2].*?>.*?<\/h[1-2]>/gim;

    this.pageContent.match(headingRegex)?.forEach(headingString => {
      const tempEl = this.renderer.createElement('div');
      tempEl.innerHTML = headingString;

      this.headingsConfig.push({
        id: tempEl.firstChild.id,
        tag: tempEl.firstChild.nodeName.toLowerCase(),
        text: tempEl.firstChild.innerText,
      });

      tempEl.remove();
    });
  }

  private watchHeadingsVisibility(): void {
    if (isPlatformBrowser(this.platformId)) {
      const callback = (
        entries: IntersectionObserverEntry[],
        _observer: IntersectionObserver
      ) => {
        this.headingsVisible = entries[0].isIntersecting;

        if (this.headingsVisible) {
          this.trailingHeadingsVisible = false;
        }

        this.trailingHeadingsHeight = !this.headingsVisible ? -50 : -0;
        this.cdr.markForCheck();
      };

      const observer = new IntersectionObserver(callback, {
        root: null,
        rootMargin: '-52px',
        threshold: 0,
      });

      observer.observe(this.wrapper.nativeElement);
    }
  }

  private changeWatermarkOnThemeChange(): void {
    effect(
      () => {
        this.watermark =
          this.themeService.rootTheme() === Theme.Light
            ? '<img class="watermark" src="assets/svg/articles/watermark.svg" />'
            : '<img class="watermark" src="assets/svg/articles/watermark-dark.png" />';

        this.cdr.detectChanges();
      },
      { injector: this.injector }
    );
  }

  private watchActiveHeading(): void {
    if (!isPlatformBrowser(this.platformId)) return;

    const callback = (
      entries: IntersectionObserverEntry[],
      _observer: IntersectionObserver
    ) => {
      this.activeTrailingHeadingId = (
        entries.filter(entry => entry.isIntersecting).at(0) || entries.at(-1)
      ).target.id;

      this.cdr.markForCheck();
    };

    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: '-28px',
      threshold: 1,
    });

    this.textEditor.nativeElement.childNodes.forEach((node: ChildNode) => {
      if (node.nodeName === 'H1' || node.nodeName === 'H2') {
        observer.observe(node as Element);
      }
    });
  }
}
